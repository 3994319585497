import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import ArticlePreview from '../components/article-preview';
import { Deal } from '../components/deal';
import { Event } from '../components/event';
import { AdUnit } from '../components/adunit';

export default ({ data }) => {
  const {
    hero,
    heroAdUnit,
    categories,
    featuredArticles,
    fullWidthAdUnit,
    featuredDeals,
    featuredDealsAds,
    featuredDealsTitle,
    featuredCategory,
    featuredCategoryArticles,
    featuredCategoryCta,
    featuredCategoryTitle,
    multipleAds,
    featuredEventsTitle,
    featuredEvents,
    featuredEventsCompanionArticlesTitle,
    featuredEventsCompanionArticles,
    bannerAd,
    socialModuleHeadline,
    socialModuleSubheadline,
    featuredSocialPosts,
  } = data.contentfulHomePage;

  return (
    <Layout mainClass="homepage">
      <div className="container">
        {hero && (
          <div className="module module__hero">
            <div className="home-hero">
              <ArticlePreview article={hero} isHero={true} />
            </div>
            {featuredArticles && (
              <div className="featured-articles-list-wrap">
                <ul
                  className={`featured-articles-list ${
                    featuredArticles.length > 3 ? 'compact' : ''
                  }`}
                >
                  {featuredArticles.map((article, i) => (
                    <li key={article.slug}>
                      <ArticlePreview
                        className="featured-article"
                        article={article}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
      {fullWidthAdUnit && (
        <AdUnit ad={fullWidthAdUnit} classString="full-width-ad" />
      )}
      <div className="container">
        {featuredDeals && (
          <div className="module module__deals">
            <div className="module-content module-content-deals">
              <h2 className="module-title">
                {featuredDealsTitle ? featuredDealsTitle : ''}
              </h2>
              <a href="/deals" className="module-cta">
                View more Deals
                <span className="material-icons md-24">arrow_forward</span>
              </a>
              <ul className="featured-deals-list">
                {featuredDeals.map(deal => (
                  <Deal key={deal.slug} deal={deal} hideToggle="true" />
                ))}
              </ul>
            </div>
            <div className="module-sidebar">
              {featuredDealsAds &&
                featuredDealsAds.map(ad => <AdUnit key={ad.id} ad={ad} />)}
            </div>
          </div>
        )}
        {/* {categories && (
          <div className="module module__category-blocks">
            <ul className="category-blocks-list">
              {categories.map(category => (
                <li
                  key={category.slug}
                  className="category-block"
                  style={{
                    backgroundImage: `url(${category.featuredImage.fluid.src})`,
                  }}
                >
                  <Link to={`/${category.slug}`}>{category.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        )} */}
        {multipleAds && (
          <div className="module module__multiple-ads">
            {multipleAds.map(ad => (
              <AdUnit key={ad.id} ad={ad} classString="block-ad" />
            ))}
          </div>
        )}
        {featuredEvents && (
          <div
            className="module module__events no-border"
            id="homepage__upcoming-events"
          >
            <div className="module-content">
              <h2 className="module-title">
                {featuredEventsTitle ? featuredEventsTitle : ''}
              </h2>
              <a href="/events" className="module-cta">
                View more Events
                <span className="material-icons md-24">arrow_forward</span>
              </a>
              <ul className="featured-events-list">
                {featuredEvents.map(event => (
                  <Event event={event} key={event.id} />
                ))}
              </ul>
            </div>
            {featuredEventsCompanionArticles && (
              <div className="module-sidebar">
                <h3 className="module-sidebar-title">
                  {featuredEventsCompanionArticlesTitle
                    ? featuredEventsCompanionArticlesTitle
                    : ''}
                </h3>
                <ol className="featured-events-articles-list">
                  {featuredEventsCompanionArticles.map((article, i) => (
                    <li key={article.slug} className="featured-events-article">
                      <span className="article-list-number">{`${i + 1}.`}</span>
                      <div>
                        <div className="article-list-meta">
                          <ul className="article-list-meta-list">
                            <li>{article.subcategories?.[0].title}</li>
                            <li>{article.categories?.[0].title}</li>
                          </ul>
                        </div>
                        <Link
                          to={`/articles/${article.slug}`}
                          className="featured-events-article-link"
                        >
                          {article.title}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        )}
        {featuredCategoryArticles && (
          <div className="module module__category">
            <h2 className="module-title">
              {featuredCategoryTitle ? featuredCategoryTitle : ''}
            </h2>
            {featuredCategory && featuredCategoryCta ? (
              <a href={`/${featuredCategory.slug}`} className="module-cta">
                {featuredCategoryCta}
                <span className="material-icons md-24">arrow_forward</span>
              </a>
            ) : (
              <div></div>
            )}
            <div className="featured-category-hero">
              <ArticlePreview
                article={featuredCategoryArticles[0]}
                isHero={true}
              />
            </div>
            <ul className="featured-category-list">
              {featuredCategoryArticles
                .filter(
                  article => article.id !== featuredCategoryArticles[0].id
                )
                .map(article => (
                  <li key={article.id}>
                    <ArticlePreview article={article} />
                  </li>
                ))}
            </ul>
          </div>
        )}
        {bannerAd && (
          <div className="banner-ad">
            <AdUnit ad={bannerAd} />
          </div>
        )}
        {featuredSocialPosts && (
          <div className="module module__social no-border">
            <div className="module-header">
              <h2 className="module-title">
                {socialModuleHeadline ? socialModuleHeadline : ''}
              </h2>
              <p className="module-subtitle">
                {socialModuleSubheadline ? socialModuleSubheadline : ''}
              </p>
            </div>
            <ul className="featured-social-list">
              {featuredSocialPosts.map(post => (
                <li key={post.id} className="social-post">
                  <a href={post.link} target="_blank" rel="noreferrer">
                    <img src={post.photo.file.url} alt={post.photo.title} />
                    {post.location && (
                      <div className="social-post-location">
                        <span className="social-post-icon icon-location"></span>
                        <span>{post.location}</span>
                      </div>
                    )}
                    {post.username && (
                      <div className="social-post-username">
                        <span className="social-post-icon icon-instagram"></span>
                        <span>{post.username}</span>
                      </div>
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomeQuery {
    contentfulHomePage {
      categories {
        title
        slug
        featuredImage {
          fluid(maxWidth: 400, resizingBehavior: FILL) {
            src
          }
        }
      }
      hero {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 1240) {
            src
          }
        }
      }
      featuredArticlesTitle
      featuredArticles {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 744, maxHeight: 342, resizingBehavior: FILL) {
            src
          }
        }
      }
      featuredDealsTitle
      featuredDeals {
        ...DealListFragment
      }
      featuredDealsAds {
        ...AdUnitFragment
      }
      featuredCategory {
        slug
      }
      featuredCategoryTitle
      featuredCategoryCta
      featuredCategoryArticles {
        ...ArticleListFragment
        heroImage {
          fluid(maxWidth: 815, maxHeight: 403, resizingBehavior: FILL) {
            src
          }
        }
      }
      featuredEventsTitle
      featuredEvents {
        ...EventListFragment
      }
      featuredEventsCompanionArticlesTitle
      featuredEventsCompanionArticles {
        title
        slug
        categories {
          title
        }
        subcategories {
          title
        }
      }
      socialModuleHeadline
      socialModuleSubheadline
      featuredSocialPosts {
        id
        photo {
          title
          file {
            url
          }
        }
        link
        username
        location
      }
      heroAdUnit {
        ...AdUnitFragment
      }
      fullWidthAdUnit {
        ...AdUnitFragment
      }
      multipleAds {
        ...AdUnitFragment
      }
      bannerAd {
        ...AdUnitFragment
      }
    }
  }
`;
